var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "modal-mobile-full",
      attrs: {
        title: "Warning",
        visible: true,
        width: "100%",
        center: "",
        fullscreen: "",
        "append-to-body": "",
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c(
          "div",
          { staticStyle: { color: "black", "margin-right": "-20px" } },
          [_vm._v("Фильтры")]
        ),
      ]),
      _c(
        "div",
        [
          _c("filter-section", {
            attrs: { option: _vm.statusMobile, "title-section": "Статус" },
          }),
          _c("filter-section", {
            attrs: {
              option: _vm.statusMobile,
              "title-section": "Дата тайм слотов",
            },
          }),
          _c("filter-section", {
            attrs: { option: _vm.statusMobile, "title-section": "Культура" },
          }),
          _c("filter-section", {
            attrs: { option: _vm.statusMobile, "title-section": "Поставщик" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "iq-button",
            {
              attrs: { size: "fullWidth" },
              on: {
                click: function ($event) {
                  _vm.centerDialogVisible = false
                },
              },
            },
            [_vm._v(" Готово ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }